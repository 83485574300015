<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div>
      <b-row>
        <b-col cols="12">
          <b-card>

            <h4>ساخت الگوی آزمون</h4>

            <!-- Media -->
            <b-media class="my-2">
              <template>
                <b-avatar
                    ref="previewEl"
                    :src="base64ImageSrc"
                    size="300px"
                    rounded
                />
              </template>
              <div class="d-flex flex-wrap mt-1">
                <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                >
                  <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="makeBase64Pic"
                  >
                  <span class="d-none d-sm-inline">+ عکس</span>
                  <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                    variant="outline-secondary"
                    class="ml-1"
                    @click="deleteProductPic()"
                >
                  <span class="d-none d-sm-inline">حذف</span>
                  <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>
            <div>
              <b-form>
                <b-row>
                  <!-- Field:  Title -->
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="عنوان"
                        label-for="Title"
                    >
                      <b-form-input
                          id="Title"
                          v-model="defaultData.title"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: productCategoryId -->
                  <b-col v-if="categories !== null"
                         cols="12"
                         md="6"
                         class="mb-2"
                  >
                    <b-form-group
                        label="دسته بندی"
                        label-for="productCategoryId"
                    >
                      <v-select
                          v-model="defaultData.categoryId"
                          :options="categories"
                          label="name"
                          :reduce="name => name.productCategoryId"
                          :clearable="false"
                          input-id="productCategoryId"
                      />
                    </b-form-group>
                    <span class="text-danger">توجه داشته باشید که هر دسته بندی فقط یک الگوی آزمون میتواند داشته باشد.</span>
                  </b-col>

                  <!-- Field:  price -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="قیمت (تومان)"
                        label-for="price"
                    >
                      <b-form-input
                          id="price"
                          v-model="defaultData.price"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: totalQuestionsCount -->
                  <b-col
                      cols="4"
                  >
                    <b-form-group
                        label="تعداد کل پرسش‌ ها"
                        label-for="totalQuestionsCount"
                    >
                      <b-form-input
                          type="number"
                          id="totalQuestionsCount"
                          v-model="defaultData.totalQuestionsCount"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: easyLevelQuestionsCount -->
                  <b-col
                      cols="4"
                  >
                    <b-form-group
                        label="تعداد پرسش‌ های آسان"
                        label-for="easyLevelQuestionsCount"
                    >
                      <b-form-input
                          type="number"
                          id="easyLevelQuestionsCount"
                          v-model="defaultData.easyLevelQuestionsCount"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: mediumLevelQuestionsCount -->
                  <b-col
                      cols="4"
                  >
                    <b-form-group
                        label="تعداد پرسش‌ های متوسط"
                        label-for="mediumLevelQuestionsCount"
                    >
                      <b-form-input
                          type="number"
                          id="mediumLevelQuestionsCount"
                          v-model="defaultData.mediumLevelQuestionsCount"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: hardLevelQuestionsCount -->
                  <b-col
                      cols="4"
                  >
                    <b-form-group
                        label="تعداد پرسش‌ های سخت"
                        label-for="hardLevelQuestionsCount"
                    >
                      <b-form-input
                          type="number"
                          id="hardLevelQuestionsCount"
                          v-model="defaultData.hardLevelQuestionsCount"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field:  description -->
                  <b-col cols="12">
                    <b-form-group
                        class="mb-2"
                        label="توضیحات"
                        label-for="product-content"
                    >
                      <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent"
                                    :defaultContent="defaultData.description"></ckEditorMain>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>

              <b-button
                  variant="primary"
                  class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="createExam"
              >
                ثبت
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay
} from 'bootstrap-vue'
import vSelect from "vue-select";
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  ProductCategoryGetAllCourseCategoriesRequest,
  ProductCategoryGetCourseCategoriesForExamRequest
} from "@/libs/Api/Product";
import {ExamCreateRequest} from "@/libs/Api/Exam";
import ckEditorMain from "@/views/components/ckEditorMain.vue";

export default {
  title: "ساخت الگوی آزمون - پنل ادمین مکس",
  name: "ExamCreate",
  components: {
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    ckEditorMain,
  },
  data() {
    return {
      overlay: false,
      propertiesData: [],
      defaultData: {
        title: "",
        description: "",
        categoryId: 0,
        price: "",
        imageFile: null,
        available: false,
        totalQuestionsCount: 0,
        easyLevelQuestionsCount: 0,
        mediumLevelQuestionsCount: 0,
        hardLevelQuestionsCount: 0,
      },
      isAvailableOptions: [
        {label: 'موجود', value: true},
        {label: 'ناموجود', value: false}
      ],
      base64ImageSrc: null,
      categories: null,
    }
  },
  async created() {
    await this.getAllCourseCategories();
  },
  methods: {
    async createExam() {
      let _this = this;
      _this.overlay = true;

      let examCreateRequest = new ExamCreateRequest(_this);
      examCreateRequest.setParams(_this.defaultData);
      await examCreateRequest.fetch(function (content) {
        _this.overlay = false;
        if (content.isSuccess) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `عملیات انجام شد.`,
            },
          })
          _this.$router.push({name:'apps-exam-list'})
        } else {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات ناموفق`,
              variant: 'danger',
              text: content.errorMessage ,
            },
          })
        }
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async getAllCourseCategories() {
      let _this = this;
      _this.overlay = true;
      let defaultGetData = {
        pageNumber: 0,
        count: 0,
      }

      let productCategoryGetAllProductCategoriesRequest = new ProductCategoryGetCourseCategoriesForExamRequest(_this);
      productCategoryGetAllProductCategoriesRequest.setParams(defaultGetData);
      await productCategoryGetAllProductCategoriesRequest.fetch(function (content) {
        _this.overlay = false;
        _this.categories = content.productCategories;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e) {
      const _this = this;
      let file = e.target.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      const result = await _this.readAsDataURL(file);
      _this.defaultData.imageFile = result;
    },
    deleteProductPic() {
      this.defaultData.imageFile = '';
      this.base64ImageSrc = null
    },
    setEditorContent(content) {
      this.defaultData.description = content;
    },
  },
}
</script>

<style scoped>

</style>